import React from "react"

import "./index.scss"

import InfoIcon from "../components/info-icon"
import ShopCard from "../components/shop-card"

import Email from "../shared_components/emailobfuscator"
import Seo from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"

const IndexPage = () => {
  return (
    <div>
      <Seo title="The Honeyverse" description="Locally sourced raw honey in San Mateo, CA. Available to pickup or by delivery." />

      <div className="info-container">
        <InfoIcon icon="fas fa-truck" title="邮寄美国本土">
          1 - 3天送达
        </InfoIcon>
        <InfoIcon icon="far fa-calendar-check" title="上门自取">
          旧金山湾区
        </InfoIcon>
        <InfoIcon icon="fas fa-spa" title="天然环保">
          100%有机纯蜂蜜
        </InfoIcon>
        <InfoIcon icon="fas fa-hand-holding-heart" title="限量供应">
          手工采集及包装
        </InfoIcon>
      </div>

      <div id="shop" style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
        <ShopCard>
          <div style={{textAlign: "center"}}>
            <div style={{fontWeight: "bold"}}>价格(净含量)</div>
            <div style={{margin: "16px"}}>
              <div>10oz jar - $20</div>
              <div>24oz jar - $40</div>
            </div>
          </div>
          <div style={{fontWeight: "bold", textAlign: "center"}}>
            San Mateo 本地产纯蜂蜜
          </div>
          <div style={{textAlign: "center"}}>
            <p>
              本地产纯蜂蜜，颜色呈淡黄琥珀色。气味带有淡淡的橙子和本地百花的清香。
            </p>
            <p>
              本地蜜蜂采蜜的植物有：加州紫丁香，薄荷，马缨丹，加州野花，蓝莓，草莓，加州紫丁香，金银花等。
            </p>
          </div>
        </ShopCard>
      </div>

      <div style={{background: "#eee", margin: "10px -4vw", padding: "5px 4vw"}}>
      <h1 id="contact">联系我们</h1>
        可电邮或Instagram下单或咨询。如需下单自取，请告知时间。
        <p>
          Email: <Email name="thehoneyverse" domain="gmail" tld="com" style={{textDecoration: "underline"}} />
        </p>
        <p>
          <i style={{color: "#FD3B6A"}} className="fab fa-instagram" aria-hidden="true" /> Instagram: <a href="https://www.instagram.com/thehoneyverse">@thehoneyverse</a>
        </p>
      </div>

      <h1 id="about">常见问题</h1>
      <div className="small-header">
        <div>蜂蜜产地是哪里？</div>
      </div>

      <div>
        <div>我们是加州圣马特奥市的一个小型蜂场，我们生产100%本地有机纯蜂蜜无任何添加。</div>
      </div>

      <div className="small-header">
        <div>你们的生产规格如何？</div>
      </div>
      <div>
        <div>我们目前有2组蜂群</div>
      </div>

      <div className="small-header">
        <div>请介绍一下你们的小蜜蜂！</div>
      </div>
      <div>
         <div>
          我们的蜜蜂卫生、温顺且在当地饲养。他们是意大利血统，被认为起源于意大利大陆部分，阿尔卑斯山以南，西西里以北。蜜蜂从加利福尼亚州圣马特奥当地自由采集当季附近的花卉和植物。我们不使用任何化学药物，而大部分商业养蜂场都会使用药物来增加蜜蜂数量从而提高产量。
        </div>
      </div>

      <div className="small-header">
        <div>吃当地产的蜂蜜有什么好处呢？</div>
      </div>
      <div>
        <div>
          本地生蜂蜜以其抗菌特性可杀死某些细菌，舒缓喉咙痛，缩短感冒。值得一提的是，因为蜂蜜是从当地植物中提取的，所以每天吃一勺当地产的蜂蜜可以帮助缓解和防治对植物或花粉过敏。
        </div>
      </div>

      <div className="small-header">
        <div>你怎么提取蜂蜜？</div>
      </div>
      <div>
        <div>
          我们手工使用高速旋转的选择器用离心法从框架中提取蜂蜜，通过过滤器去除大块蜜蜡及杂质，然后就直接包装。100%天然，无添加。
        </div>
      </div>

      <div className="small-header">
        <div>
          你为什么不用塑料瓶包装？
        </div>
      </div>
      <div>
        储存蜂蜜的最佳方法是在玻璃罐中。所有纯蜂蜜都会结晶。液化结晶蜂蜜，最好方法是将容器中的蜂蜜在 150F/65C 的水中加热。塑料可能会在此温度下熔化，因此玻璃罐更好！真正的纯蜂蜜是不会像超市里的商业蜂蜜那么快的结晶的，保质时间更久。
      </div>

      <div className="small-header">
        <div>我应该买多大的瓶子？</div>
      </div>
      <div>
        如果您想在饮品中加入蜂蜜，建议每1杯液体加入1-2茶匙的蜂蜜。 
        6盎司的罐子里大约有20茶匙，10盎司罐子里大约有33茶匙，24盎司罐子里大约有80茶匙.
      </div>

      <div className="small-header">
        <div>孕期或哺乳期妈妈可以吃蜂蜜吗？</div>
      </div>
      <div>
        可以，孕期或哺乳期妈妈可以放心食用。
      </div>

      <div className="small-header">
        <div>宝宝可以吃蜂蜜吗？</div>
      </div>
      <div>
        一岁以下的宝宝不可以吃蜂蜜，两岁以下的宝宝也不建议吃蜂蜜。
      </div>
    </div>
  )
}

export default IndexPage
